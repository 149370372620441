import Experiments from '@wix/wix-experiments';

let conductedExperiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function areAppWidgetsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AppWidgets') === 'true';
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function isControllersDeletionForEditorXEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.EditorXControllersDeletion') === 'true';
}

async function isAppsManagerEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AppsManager') === 'true';
}

async function shouldDisableBrokenMADeletion() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.disableAutoRemoval') === 'true';
}

async function isAddMinHeightEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.AddMinHeight') === 'true';
}

async function shouldAlwaysAddProfileToManagePages() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ProfilePageInManagePages') === 'true';
}

async function isBWProfileEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.BWProfile') === 'true';
}

async function isAddUninstallMAActionEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AddUninstallMAAction') === 'true';
}

async function isPlatformUninstallationEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.PlatformUninstallation') === 'true';
}

async function areTransactionsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.EnableEditorTransactions') === 'true';
}

export {
  areAppWidgetsEnabled,
  maybeConductExperiments,
  isADIHorizontalLayoutEnabled,
  isControllersDeletionForEditorXEnabled,
  isAppsManagerEnabled,
  shouldDisableBrokenMADeletion,
  isAddMinHeightEnabled,
  shouldAlwaysAddProfileToManagePages,
  isBWProfileEnabled,
  isAddUninstallMAActionEnabled,
  isPlatformUninstallationEnabled,
  areTransactionsEnabled,
};
