import * as history from '../wrappers/history';
import _ from 'lodash';
import * as membersLogic from '../membersLogic';
import * as menus from '../wrappers/menus';
import * as routers from '../wrappers/routers';
import * as pages from '../wrappers/pages.ts';
import enforceSequentiality from '../enforceSequentiality.ts';
import { PERMISSION_STATES } from '../constants';
import { toMonitored } from '../../utils/monitoring';
import { removeMembersAreaPage } from '../platform-api/removeMembersAreaPage';
import { hasSocialPages } from '../services/pages';

export default (editorSDK) => ({
  hasSocialPages: () => {
    return enforceSequentiality(() => hasSocialPages(editorSDK));
  },
  removePage: (pageData) => {
    return enforceSequentiality(() => removeMembersAreaPage({ editorSDK, id: pageData.id }));
  },
  uninstall: () => {
    return enforceSequentiality(() =>
      toMonitored('uninstall', () => {
        membersLogic.uninstall(editorSDK);
      }),
    );
  },
  getRouterDataByPage: (pageRef) => {
    return enforceSequentiality(() => routers.getPageRouterData(editorSDK, pageRef));
  },
  getAllRouters: () => {
    return enforceSequentiality(() => routers.getAll(editorSDK));
  },
  updatePageDataInRouter: (pageRef, dataFieldToUpdate, updatedData) => {
    return enforceSequentiality(() => routers.updatePageData(editorSDK, pageRef, dataFieldToUpdate, updatedData));
  },
  saveInnerRoute: (newInnerRoute, pageRef, pageRouterData) => {
    return enforceSequentiality(async () => {
      let savedInnerRoute = await routers.changePagePattern(editorSDK, pageRef, newInnerRoute);
      if (savedInnerRoute && savedInnerRoute[0] === '/') {
        savedInnerRoute = savedInnerRoute.substr(1);
      }

      await menus.updateMenuItemInAllMenus({
        editorSDK,
        pageRouterData: pageRouterData,
        updatedData: { link: { innerRoute: savedInnerRoute } },
      });
      history.addWithDelay(editorSDK, 'Members update uri seo');
    });
  },

  /* Pages */
  updatePageData: ({ pageRef, pageData }) => {
    enforceSequentiality(() => pages.updatePageData({ editorSDK, pageRef, pageData }));
  },
  savePasswordPermissions: async (pageRef, privacy, plainPassword) => {
    if (privacy === PERMISSION_STATES.PASSWORD && plainPassword) {
      await pages.updatePageData({ editorSDK, pageRef, pageData: { pageSecurity: { requireLogin: false } } });
      enforceSequentiality(() => pages.updatePagePassword({ editorSDK, pageRef, plainPassword }));
    } else {
      const shouldRequireLogin = !_.isEqual(privacy, PERMISSION_STATES.NONE);
      await pages.updatePageData({
        editorSDK,
        pageRef,
        pageData: { pageSecurity: { requireLogin: shouldRequireLogin } },
      });
      enforceSequentiality(() => pages.removePagePassword({ editorSDK, pageRef }));
    }
  },
  /* PageInfo */
  renamePage: (pageName, pageRef, pageRouterData) => {
    enforceSequentiality(async () => {
      const PpageRename = pages.rename({
        editorSDK,
        pageRef,
        title: pageName,
      });
      const PmenuUpdateItem = menus.updateMenuItemInAllMenus({
        editorSDK,
        pageRouterData,
        updatedData: { label: pageName },
      });
      const ProuterDataUpdate = routers.updatePageData(editorSDK, pageRef, 'title', pageName);
      const ProuterSeoTitleUpdate = routers.updatePageData(editorSDK, pageRef, 'seoData', { title: pageName });

      await Promise.all([PpageRename, PmenuUpdateItem, ProuterDataUpdate, ProuterSeoTitleUpdate]);
      history.add(editorSDK, 'Members page rename');
    });
  },
});
