import * as membersLogic from './membersLogic';
import * as pages from './pages';
import * as componentsWrapper from './wrappers/components';
import * as controllersWrapper from './wrappers/controllers';
import * as pagesWrapper from './wrappers/pages.ts';
import * as pagesService from './services/pages';
import * as pagesGroupWrapper from './wrappers/pagesGroup';
import * as routersWrapper from './wrappers/routers';
import * as menusWrapper from './wrappers/menus';
import * as constants from './constants';
import * as applicationState from './applicationState';
import * as routersService from './services/routers';
import i18next from '../i18n';
import createAppApi from './public-api';
import enforceSequentiality from './enforceSequentiality.ts';
import { SITE_MEMBERS_BM_URL, BADGES_BM_URL } from './constants/routes.ts';
import {
  initializeMonitoring,
  toMonitored,
  log,
  interactionStarted,
  interactionEnded,
  interactionFailed,
} from '../utils/monitoring';
import { createAppManifest } from './manifest';
import { addApplications, createConnectionConfigs } from './platform-api/addApplications';
import * as menusService from './services/menus';
import { getSiteLocale, getEditorLocale } from '../utils/locale';
import { removeMembersAreaPageByPageId } from './platform-api/removeMembersAreaPage';
import { setHorizontalLayout, setSidebarLayout } from './platform-api/layouts';
import { parseStaticsUrlFromEditorScriptUrl } from './services/urls';
import * as appState from './services/applicationState.ts';
import { createBIService } from '../utils/bi.ts';
import * as membersIntegrationApi from './services/integration.ts';
import { getAppDefinitions } from '@wix/members-area-app-definitions';
import { addMinHeight } from './services/pages';
import { publishSettingsForMembersAreaApps, refreshMembersAreaApps } from './services/members-area.ts';
import { maybeConductExperiments, shouldDisableBrokenMADeletion } from '../utils/experiments';
import { getProfileType, setProfileType } from './services/profile.ts';
import { registerAlwaysAvailableApps } from './services/integration.ts';
import { runAndWaitForApproval } from './wrappers/transactions';

const { APP_TOKEN } = constants;
let editorSDK;
let publicApi;
let resolveEditorReady;

enforceSequentiality(
  new Promise((resolve) => {
    resolveEditorReady = resolve;
  }),
);

// TO DO: Login button element removal is missing
async function removeBrokenInstallation(_editorSDK, shouldLog = true, forceDelete = false) {
  if (!forceDelete && (await shouldDisableBrokenMADeletion())) {
    return;
  }

  if (shouldLog) {
    const state = await applicationState.getApplicationComponents(_editorSDK);
    const isEmpty = await applicationState.isEmpty(_editorSDK);
    const extra = { state: JSON.stringify(state) };

    if (isEmpty) {
      log('Removing components for empty installation', { extra });
    } else {
      log('Removing components for broken installation', { extra });
    }
  }

  try {
    await pages.navigateToFirstPrivatePage(editorSDK);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  await componentsWrapper.removeSospContainer(_editorSDK);
  await pagesGroupWrapper.remove(_editorSDK);

  try {
    if (await pages.isInMembersAreaSubPage(editorSDK)) {
      await pages.navigateToHomePage(editorSDK);
    }
  } catch (e) {
    log('Failed to check if MA is in the home page, continuing broken MA uninstallation', { extra: e });
  }

  await routersWrapper.removeConnectedPages(_editorSDK);
  await routersWrapper.removeAllRouters(_editorSDK);
  await controllersWrapper.wipeOut(_editorSDK);
  await menusWrapper.removeMenus(_editorSDK);
}

async function removeComponentsForBrokenInstallation(_editorSDK) {
  const successfullyInstalled = await applicationState.isApplicationReady(_editorSDK, { shouldLog: true });
  if (!successfullyInstalled) {
    await toMonitored('editorReady.removeComponentsForBrokenInstallation', () => removeBrokenInstallation(_editorSDK));
  }
}

async function maybeInstallMissingRouters() {
  // Just logging for now
  const routers = await routersWrapper.getAll(editorSDK);

  if (routers.length !== 2) {
    const state = await applicationState.getApplicationComponents(editorSDK);
    const extra = {
      routers: JSON.stringify(routers),
      applicationState: JSON.stringify(state),
    };
    log('Missing routers when they should be apparent ', { extra });
  }
}

async function maybeFixBrokenMenuItems() {
  const menuIds = menusWrapper.getMenuIds();
  const menus = await Promise.all([
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.members }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.login }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.icons }),
  ]);

  // If all menus aren't apparent, don't fix them - MA state is too broken already
  if (menus.filter((m) => !!m).length !== 3) {
    return;
  }

  await menusService.maybeCleanUpMenus(editorSDK);
}

async function maybeFixBrokenInstallation() {
  try {
    await maybeInstallMissingRouters();
    await maybeFixBrokenMenuItems();
  } catch (e) {
    log('An errow was thrown while fixing a broken installation, reason: ' + e);
  }
}

async function onManagePages(eventPayload) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({ origin: 'editor' });
  editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: './assets/managePages.html',
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

async function getUniqueNewPublicPageUriSeo(_editorSDK, initialPrefix = 'blank') {
  let pageUriSeo;

  const routers = await routersWrapper.getAll(_editorSDK);
  const currentPageUris = Object.keys(
    routers.find((router) => router.config.type === 'public').config.patterns || {},
  ).map((pattern) => pattern.split('/{userName}/')[1]);

  let counter = 1;
  let isUnique;

  do {
    pageUriSeo = `${initialPrefix}-${counter}`;
    isUnique = !currentPageUris.includes(pageUriSeo);
    counter++;
  } while (!isUnique && counter < 1000);

  return pageUriSeo;
}

async function createBlankPage(_editorSDK, isPrivate = true) {
  let pageRef;

  try {
    const locale = await getSiteLocale(_editorSDK);
    const i18n = await i18next(appState.getStaticsBaseUrl(), locale);
    const t = i18n.t.bind(i18n);
    const pageUriSEO = isPrivate ? undefined : await getUniqueNewPublicPageUriSeo(_editorSDK);
    const pageTitle = isPrivate ? t('Pages_New_Private_Page_Title') : t('Pages_New_Public_Page_Title');
    pageRef = await pagesWrapper.addPage({ editorSDK: _editorSDK, pageTitle, pageUriSEO });
    await addMinHeight([{ pageRef }], _editorSDK);
    if (!isPrivate) {
      await pagesWrapper.updatePageData({
        editorSDK: _editorSDK,
        pageRef,
        pageData: { pageSecurity: { requireLogin: false }, hidePage: false },
      });
    }

    const createdPage = await pagesWrapper.getPageData({ editorSDK: _editorSDK, pageRef });
    const routers = (await routersService.getMembersAreaRouters(_editorSDK)) || { publicRouter: {}, privateRouter: {} };
    createdPage.pageRef = pageRef;
    const apps = [
      {
        appDefinitionId: createdPage.managingAppDefId,
        pageId: '',
        social: !isPrivate,
        showInLoginMenu: true,
        showInMemberMenu: true,
        loginMenuTitle: false,
      },
    ];
    const connectionConfigs = createConnectionConfigs({ applications: apps, pages: [createdPage], routers });
    await pagesService.connectPagesToMembers({ editorSDK: _editorSDK, pages: connectionConfigs });
    await pagesService.setStateForPages(_editorSDK);
  } catch (e) {
    log('Add custom page failed', {
      tags: { reason: e.toString() + '\n' + e.stack, isPrivate, pageRefAdded: !!pageRef },
    });
  }
}

async function onEvent({ eventType, eventPayload }) {
  enforceSequentiality(async () => {
    const isReady = await applicationState.isApplicationReady(editorSDK);
    if (!isReady) {
      return;
    }
    try {
      const _routers = await editorSDK.routers.getAll();
      switch (eventType) {
        case 'createBlankPage': {
          onManagePages(eventPayload);
          break;
        }
        case 'managePages': {
          onManagePages();
          break;
        }
        case 'pageDeleted': {
          // Handling when a verticals TPA section is being deleted - removes menu items and router patterns, the page is removed by platform
          // Separate applications deletion (added with "addApplication") is handled by handleVerticalDeletion
          // To do: ask Editor Platform to do this on their side when deleting
          // Seems like we can't return the promise because it then does not execute on e.g. Bookings deletion..
          enforceSequentiality(() =>
            toMonitored('handleVerticalSectionDeletion', () =>
              membersIntegrationApi.handleVerticalSectionDeletion(editorSDK, eventPayload.pageRole),
            ),
          );
          break;
        }
        case 'uninstall': {
          editorSDK.editor.openModalPanel(APP_TOKEN, {
            url: './assets/uninstall.html',
            width: 564,
            height: 309,
            shouldHideHeader: true,
            initialData: eventPayload,
          });
          break;
        }
        case 'removePage': {
          editorSDK.editor.openModalPanel(APP_TOKEN, {
            url: './assets/removePage.html',
            width: 564,
            height: 258,
            shouldHideHeader: true,
            initialData: eventPayload,
          });
          break;
        }
        case 'renameRouter':
          publicApi.hasSocialPages().then((hasSocialPages) => {
            const height = hasSocialPages
              ? constants.RENAME_ROUTER_PANEL_HEIGHT + 150
              : constants.RENAME_ROUTER_PANEL_HEIGHT;
            editorSDK.editor.openModalPanel(APP_TOKEN, {
              url: './assets/renameRouter.html',
              width: 744,
              height,
              shouldHideHeader: true,
              initialData: Object.assign({ routers: _routers }, eventPayload),
            });
          });
          break;
        case 'componentAddedToStage':
          await componentsWrapper.handleCompAddedToStage(editorSDK, eventPayload.compRef);
          break;
        case 'siteWasPublished':
          publishSettingsForMembersAreaApps(editorSDK);
          break;

        // Apps manager
        case 'appActionClicked':
          switch (eventPayload && eventPayload.actionId) {
            case 'openMembersAreaPagesPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'pagesPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'addMemberPage': {
              onManagePages(eventPayload);
              break;
            }
            case 'openMembersAddPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'addPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'openBadgesPage':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: BADGES_BM_URL, closeOtherPanels: true });
              break;
            case 'openSiteMembersDashboard':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: SITE_MEMBERS_BM_URL, closeOtherPanels: true });
              break;
            default:
              break;
          }
          break;

        /* end of possibly unused events */
        default:
          console.log(eventType, eventPayload);
      }
    } catch (e) {
      throw e;
    }
  });
}

// For investigation purposes of MA-84
async function verifyMyAccountPage(options) {
  if (options.firstInstall) {
    return;
  }

  try {
    const menuItems = await menusWrapper.getMenuItems({ editorSDK, menuId: constants.MENU_IDS.SUB_MENU_ID });
    const myAccountMenuItem = menuItems.find((i) => i.link.innerRoute === 'my-account');
    const allPages = await editorSDK.pages.data.getAll();
    const myAccountPage = allPages.find((p) => p.tpaPageId === 'member_info');

    if (!myAccountPage && !!myAccountMenuItem) {
      log('editorLoad: MA-84 My account menu item is there, but the page is missing');
      return;
    }
    // eslint-disable-next-line
  } catch (e) {
    log('Verifying My Account page failed', { tags: { reason: e.toString() } });
  }
}

async function verifyNoMissingLoginInADI(firstInstall) {
  try {
    if (!appState.getIsADI()) {
      return;
    }

    const hasLoginComponent = await componentsWrapper.isLoginBarComponentAdded({ editorSDK });

    if (!hasLoginComponent) {
      log('OB-19052: Login component missing in ADI', { tags: { firstInstall } });
      try {
        const controllerRef = await controllersWrapper.getController(editorSDK);
        const header = await editorSDK.siteSegments.getHeader();
        await componentsWrapper.addLoginButton(editorSDK, controllerRef, header);
      } catch (e) {
        log('OB-19052: Re-adding login component failed', { tags: { reason: e.toString() } });
      }
    }
  } catch (e) {
    log('OB-19052: Verification of login component failed', { tags: { reason: e.toString() } });
  }
}

async function maybeRemoveLeftoversFromUnsuccessfulInstallation(_editorSDK, options) {
  const isEmpty = await applicationState.isEmpty(_editorSDK);
  const isReady = await applicationState.isApplicationReady(_editorSDK, { shouldLog: false });
  if (options.firstInstall && !isEmpty && !isReady) {
    const state = await applicationState.getApplicationComponents(_editorSDK);
    await removeBrokenInstallation(_editorSDK, false);
    log('Removing leftover components from previous installations', { extra: { state: JSON.stringify(state) } });
  }
}

async function maybeRemoveEmptyInstallation(_editorSDK, options) {
  const isEmpty = await applicationState.isEmpty(_editorSDK);
  if (!options.firstInstall && isEmpty) {
    log('Removing components for empty installation as it will not install anyway and will surely fail');
    await removeBrokenInstallation(_editorSDK, false);
    return { shouldContinueInitialization: false };
  }
  return { shouldContinueInitialization: true };
}

async function maybeInstallMembersArea(_editorSDK, options) {
  if (await membersLogic.shouldInstall(_editorSDK, options.firstInstall)) {
    try {
      await toMonitored('install', () => membersLogic.install(_editorSDK, options));
    } catch (e) {
      log('Removing initial installation as it failed', { tags: { reason: e.toString() } });
      await removeBrokenInstallation(_editorSDK, true, true);
      throw e;
    }
  }
}

// Old sites pages does not have managingAppDefId, which is needed for platformised pages panel
async function maybeSetManagingAppDefIdForMAPages({ editorSDK: editorSDK_, options }) {
  if (options.firstInstall) {
    return;
  }

  try {
    const routers = await routersWrapper.getAll(editorSDK_);
    const pagesRefs = routers.reduce((acc, router) => [...acc, ...router.pages.map((p) => p.pageRef)], []);
    await Promise.all(
      pagesRefs.map((pageRef) => pagesService.updatePageWithManagingAppDefId({ editorSDK: editorSDK_, pageRef })),
    );
  } catch (e) {
    log('Failed to set managingAppDefId for MA pages, reason:', e.toString());
  }
}

async function exposePlatformAppAPI(_editorSDK) {
  publicApi = createAppApi(_editorSDK);
  await _editorSDK.editor.setAppAPI(APP_TOKEN, publicApi);
  resolveEditorReady();
}

async function editorReady(_editorSDK, _appToken, options = {}) {
  maybeConductExperiments();

  editorSDK = _editorSDK;
  appState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl));
  appState.setIsBlogWriterProfilesOnly(options.origin.info?.blogWriterProfilesOnly);
  appState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE');
  appState.setIsADI(options.origin.type.indexOf('ADI') === 0);

  const biService = await createBIService({ editorSDK });

  try {
    await initializeMonitoring(editorSDK, options);
    interactionStarted('editorReady');

    await runAndWaitForApproval(editorSDK, async () => {
      await maybeSetManagingAppDefIdForMAPages({ editorSDK, options });

      await pagesService.setStateForPages(editorSDK);

      // We see occasions of not entirely deleted MAs without the app actually being installed.
      // This should clean it up and allow a proper installation
      // This shouldn't be happening so need to investigate why this is happening
      await maybeRemoveLeftoversFromUnsuccessfulInstallation(editorSDK, options);

      // Delete empty MAs which won't install anyway to not cause further errors
      // Also don't expose public API in such case as it will not perform properly without MA components
      const { shouldContinueInitialization } = await maybeRemoveEmptyInstallation(editorSDK, options);
      if (!shouldContinueInitialization) {
        return resolveEditorReady();
      }

      // MA-84 investigation, making sure My Account page is always there as it has to be
      await verifyMyAccountPage(options);

      // Install MA and delete it if anything goes wrong
      await maybeInstallMembersArea(editorSDK, options);

      // Try to solve some issues like duplicated menu items and etc, where MA is corrupted but doesn't have to be deleted
      await maybeFixBrokenInstallation();

      // Expose the platform app API
      await exposePlatformAppAPI(editorSDK);

      // Remove MA if it is still unsuccessfully installed
      await removeComponentsForBrokenInstallation(editorSDK);

      // OB-19052 investigation, check if login component is missing in ADI
      await verifyNoMissingLoginInADI(options.firstInstall);

      await registerAlwaysAvailableApps(editorSDK);
    });
    interactionEnded('editorReady');
  } catch (e) {
    biService.logInstallationFailure(e.toString());
    interactionFailed('editorReady');
    console.error('membersApplication installation failed.', e);
    console.error('things will certainly not work properly from here on');
    throw new Error('Members Area installation failed: ' + e);
  }
}

async function getAppManifest() {
  const locale = await getEditorLocale(editorSDK);
  const i18n = await i18next(appState.getStaticsBaseUrl(), locale);
  const appManifest = createAppManifest(editorSDK, i18n.t.bind(i18n));
  return appManifest;
}

async function getControllerPresets() {
  return Promise.resolve([]);
}

async function handleAction() {}

function maybeAddApplications(applications, shouldNavigate) {
  return enforceSequentiality(async () => {
    const isReady = await applicationState.isApplicationReady(editorSDK);
    if (!isReady) {
      console.warn('Members Area installation was corrupted so the integrations pages will not be added');
      log('Skipping addApplications as the application is not ready and probably already deleted');
      return;
    }
    const applicationDefinitions = await getAppDefinitions({ applications, editorSDK });

    return toMonitored('editorApi.addApplications', () =>
      addApplications({ editorSDK, applications: applicationDefinitions, shouldNavigate }),
    );
  });
}

async function refreshPageState() {
  await pagesService.setStateForPages(editorSDK);
}

const transaction = (action) => (...props) => runAndWaitForApproval(editorSDK, () => action(...props));

const exports_ = {
  addApplications: transaction(maybeAddApplications),
  getMembersPageRef: ({ appDefinitionId, appPageId } = {}) => {
    return routersWrapper.findPageRefByAppData(editorSDK, appDefinitionId, appPageId);
  },
  removeMembersAreaPage: (pageId, appDefinitionId) =>
    enforceSequentiality(() =>
      toMonitored(
        'editorApi.removeMembersAreaPage',
        transaction(() => removeMembersAreaPageByPageId({ pageId, appDefinitionId, editorSDK })),
      ),
    ),
  setHorizontalLayout: () =>
    enforceSequentiality(() =>
      toMonitored(
        'editorApi.setHorizontalLayout',
        transaction(() => setHorizontalLayout(editorSDK)),
      ),
    ),
  setSidebarLayout: () =>
    enforceSequentiality(() =>
      toMonitored(
        'editorApi.setSidebarLayout',
        transaction(() => setSidebarLayout(editorSDK)),
      ),
    ),
  _getIsResponsiveEditor: () => enforceSequentiality(() => appState.getIsResponsiveEditor()),
  handleVerticalDeletion: transaction((verticalAppDefId) =>
    membersIntegrationApi.handleVerticalDeletion(verticalAppDefId, editorSDK),
  ),
  registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) =>
    membersIntegrationApi.registerMembersAreaApps(applications, verticalAppDefId, editorSDK, applicationsOptions),
  installRegisteredApps: transaction((verticalAppDefId) =>
    membersIntegrationApi.installRegisteredApps(verticalAppDefId, editorSDK),
  ),
  getRegisteredApps: () => membersIntegrationApi.getRegisteredApps(editorSDK),
  addCustomPage: transaction((isPrivate) => createBlankPage(editorSDK, isPrivate)),
  refreshPageState,
  getProfileType: () => getProfileType(editorSDK),
  setProfileType: transaction((type) => setProfileType(editorSDK, type)),
  refreshRouters: () => routersWrapper.refreshRouters(editorSDK),
  refreshMembersAreaApps: () => refreshMembersAreaApps(editorSDK),
  registerAdditionalWidgets: () => {},
  getAdditionalWidgets: () => {},
  installAdditionalWidgets: () => {},
};

export { editorReady, onEvent, getAppManifest, getControllerPresets, handleAction, exports_ as exports };
