import { EditorSDK } from '@wix/platform-editor-sdk';
import { WIX_BLOG } from '@wix/app-definition-ids';
import { getAppDefinitions } from '@wix/members-area-app-definitions';
import {
  IntegrationApplication,
  IntegrationApplicationMap,
  MembersAreaAppsOptions,
} from '@wix/members-area-integration-kit';

import * as state from './applicationState';
import * as routersService from './routers';
import * as menusWrapper from '../wrappers/menus';
import enforceSequentiality from '../enforceSequentiality';
import { toMonitored, log } from '../../utils/monitoring';
import { isApplicationReady } from '../applicationState';
import { addApplications } from '../platform-api/addApplications';
import { removeMembersAreaPage } from '../platform-api/removeMembersAreaPage';
import { asyncFilter } from '../../utils/promises';
import { createBIService } from '../../utils/bi';
import { getPageByIntegrationApp, hasSocialPages } from './pages';
import { isMyWishlist } from './myWishlistIntegration';
import { removeLoosePagesOutsideMembersArea } from '../data-fixers/pages';
import { isBWProfileEnabled, shouldAlwaysAddProfileToManagePages } from '../../utils/experiments';
import { getUniquePages } from '../../utils/pages';
import {
  ALWAYS_REGISTERED_APPS,
  APP_TOKEN,
  ALWAYS_REGISTERED_SOCIAL_MA_APPS,
  MY_ACCOUNT_APP_DEF_ID,
} from '../constants';
import { resetRolesOnSocialVertical, setProfileType, getProfileType, ProfileType, hasOnlyBlogProfile } from './profile';
import { setIntegratedApps, getIsUninstalling } from './applicationState';

const isAddApplication = (app: IntegrationApplication) => app.method === 'addApplication';
const hasNoDependencies = (allApps: IntegrationApplication[]) => (app: IntegrationApplication) =>
  !allApps.some((app2) => app2.pageId === app.pageId);
const isAppInstalled = (editorSDK: EditorSDK) => (appDefinitionId: string) =>
  editorSDK.document.tpa.isApplicationInstalled(APP_TOKEN, { appDefinitionId });

const maybeAddApplications = async (applications: IntegrationApplication[], editorSDK) => {
  const isReady = await isApplicationReady(editorSDK);
  if (!isReady) {
    console.warn('Members Area installation was corrupted so the integrations pages will not be added');
    log('Skipping addApplications as the application is not ready and probably already deleted');
    return;
  }
  const forceHorizontalLayout = false;
  return toMonitored('editorApi.addApplications', () =>
    addApplications({ editorSDK, applications, forceHorizontalLayout }),
  );
};

const removePage = ({ id, editorSDK }) =>
  toMonitored('editorApi.removeMembersAreaPage', () => removeMembersAreaPage({ editorSDK, id }));

export const registerMembersAreaApps = (
  applications: IntegrationApplication[],
  verticalAppDefId: string,
  editorSDK,
  applicationsOptions: MembersAreaAppsOptions,
) =>
  toMonitored('editorApi.registerMembersAreaApps', async () => {
    const applicationDefinitions = await getAppDefinitions({ applications, editorSDK, applicationsOptions });
    const currentIntegratedAppsMap = state.getAllIntegratedApps();
    const verticalsApps = getUniquePages([
      ...(currentIntegratedAppsMap[verticalAppDefId] ?? []),
      ...applicationDefinitions,
    ]);
    if (await isBWProfileEnabled()) {
      await resetRolesOnSocialVertical(editorSDK, verticalAppDefId, verticalsApps);
    }
    state.setIntegratedApps({
      ...currentIntegratedAppsMap,
      [verticalAppDefId]: verticalsApps,
    });

    enforceSequentiality(() => removeLoosePagesOutsideMembersArea(editorSDK, applications));
  });

export const installRegisteredApps = async (verticalAppDefId: string, editorSDK) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  return enforceSequentiality(() =>
    toMonitored('editorApi.installRegisteredApps', async () => {
      const integrationApps = state.getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
      const integrationAppsToInstall = integrationApps.filter((app) => app.shouldInstallInitially !== false);

      if (integrationAppsToInstall.length > 0) {
        await maybeAddApplications(integrationAppsToInstall, editorSDK);
      }

      await maybeSetBlogWriterProfileType(editorSDK, verticalAppDefId);

      biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
    }),
  );
};

const maybeSetBlogWriterProfileType = async (editorSDK, verticalAppDefId) => {
  try {
    if (
      verticalAppDefId === WIX_BLOG &&
      !state.getIsADI() &&
      (await isBWProfileEnabled()) &&
      (await hasOnlyBlogProfile(editorSDK))
    ) {
      const profileType = state.getIsBlogWriterProfilesOnly() ? ProfileType.BWP_ONLY : ProfileType.BWP_ALL;
      const currentProfileType = await getProfileType(editorSDK);
      if (profileType !== currentProfileType) {
        await setProfileType(editorSDK, profileType);
      }
    }
  } catch (e) {
    log('Failed to set Blog Profile type', { extra: { error: e } });
  }
};

export const handleVerticalDeletion = (verticalAppDefId: string, editorSDK) =>
  enforceSequentiality(() =>
    toMonitored('editorApi.handleVerticalDeletion', async () => {
      const verticalsApps = state.getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
      const allIntegratedAppsMap: IntegrationApplicationMap = state.getAllIntegratedApps();
      const installedVerticalIds: string[] = await asyncFilter(
        Object.keys(allIntegratedAppsMap),
        isAppInstalled(editorSDK),
      );
      const appsOfOtherVerticals = installedVerticalIds.reduce<IntegrationApplication[]>(
        (acc, appDefId) => acc.concat((allIntegratedAppsMap[appDefId] as IntegrationApplication[]) ?? []),
        [],
      );
      const integratedAppsToDelete = verticalsApps
        .filter(hasNoDependencies(appsOfOtherVerticals))
        // TPA Section deletion is handled in "pageDeleted" event
        .filter(isAddApplication);

      for (const app of integratedAppsToDelete) {
        const pageToRemove = await getPageByIntegrationApp({ editorSDK, app });
        if (pageToRemove) {
          await removePage({ editorSDK, id: pageToRemove.id });
        }
      }
      if (integratedAppsToDelete.length > 0 && state.getIsADI()) {
        editorSDK.application.reloadManifest();
      }
    }),
  );

export const registerAlwaysAvailableApps = async (editorSDK) => {
  if (state.getIsADI() || !(await shouldAlwaysAddProfileToManagePages())) {
    return;
  }

  const isSocialMA = await hasSocialPages(editorSDK);
  const santaMembersApps = await getAppDefinitions({
    applications: ALWAYS_REGISTERED_APPS.concat(isSocialMA ? ALWAYS_REGISTERED_SOCIAL_MA_APPS : []),
    editorSDK,
  });

  setIntegratedApps({
    ...state.getAllIntegratedApps(),
    [MY_ACCOUNT_APP_DEF_ID]: santaMembersApps,
  });
};

export const getRegisteredApps = (editorSDK) =>
  enforceSequentiality(() =>
    toMonitored('editorApi.getRegisteredApps', async () => {
      const allIntegratedAppsMap = state.getAllIntegratedApps();
      const registeredVerticalIds = Object.keys(allIntegratedAppsMap);
      const installedVerticalIds: string[] = await asyncFilter(registeredVerticalIds, isAppInstalled(editorSDK));
      const filteredAppsMap = await installedVerticalIds.reduce<Promise<IntegrationApplicationMap>>(
        async (acc, appDefId) => ({
          ...(await acc),
          [appDefId]: await asyncFilter(
            allIntegratedAppsMap[appDefId],
            async (app) => !(await getPageByIntegrationApp({ editorSDK, app })) && !isMyWishlist(app),
          ),
        }),
        Promise.resolve({}),
      );

      return filteredAppsMap;
    }),
  );

export const getOnlyInstalledVerticals = async (editorSDK) => {
  const allVerticals = state.getAllIntegratedApps();
  const installedVerticalIds: string[] = await asyncFilter(Object.keys(allVerticals), isAppInstalled(editorSDK));
  const installedVerticals = installedVerticalIds.reduce<IntegrationApplicationMap>(
    (verticals, verticalId) => ({
      ...verticals,
      [verticalId]: allVerticals[verticalId],
    }),
    {},
  );
  return installedVerticals;
};

export const handleVerticalSectionDeletion = async (editorSDK: EditorSDK, pageRole: string) => {
  // No need to handle if MA is being deleted as a whole
  if (getIsUninstalling()) {
    return;
  }

  const routers = await routersService.getMembersAreaRouters(editorSDK);
  const { patternKey, router } = routersService.getPatternByPageRole({ routers, pageRole });

  if (!patternKey) {
    return;
  }

  try {
    await routersService.deleteRouterPatternByPatternKey({ editorSDK, router, patternKey });
  } catch (e) {
    log('Failed to remove pattern from router when handling vertical section deletion', { e: { patternKey, router } });
  }

  try {
    const pattern = patternKey.replace(/\/?(.*)/, '$1');
    await menusWrapper.removePatternFromAllMenus({ editorSDK, pattern });
  } catch (e) {
    log('Failed to remove pattern from menus when handling vertical section deletion', { e: { patternKey, router } });
  }
};
